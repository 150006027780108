import React, { useEffect } from 'react'

const Support: React.FC = () => {
  useEffect(() => {
    window.location.href = process.env.GATSBY_SUPPORT_URL
  }, [])

  return <div></div>
}

export default Support
